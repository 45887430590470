import GuidUtils from '@/shared/utils/guid'
import SchoolTerms from '@/shared/enums/schoolTerms'
import Genders from '@/shared/enums/gender.js'
import YesNo from '@/shared/enums/yesNo.js'

export default class WaitingList {
  constructor({
    id,
    name,
    gender,
    rg,
    cpf,
    birthDate,
    schoolTerm,
    hasDeficiency,
    deficiencyTypeId,
    observations,
    registrationDate,
    cidId,
    address,
    contacts,
    localTrainingModalities
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.name = name
    this.gender = gender
    this.genderName = this.getGender(gender)
    this.rg = rg
    this.cpf = cpf
    this.birthDate = birthDate
    this.schoolTerm = schoolTerm
    this.schoolTermName = this.getSchoolTerm(schoolTerm)
    this.hasDeficiency = hasDeficiency
    this.hasDeficiencyName = this.getHasDeficiency(hasDeficiency)
    this.deficiencyTypeId = deficiencyTypeId
    this.observations = observations
    this.registrationDate = registrationDate
    this.cidId = cidId
    this.address = address
    this.contacts = contacts ? contacts : []
    this.localTrainingModalities = localTrainingModalities ? localTrainingModalities : []
  }

  getSchoolTerm(schoolTerm){
    const schoolTermObj = Object.values(SchoolTerms).find(st => st.id === schoolTerm)

    if(!schoolTermObj) return '-'

    return schoolTermObj.name
  }

  getGender(gender) {
    const genderObj = Object.values(Genders).find(g => g.id === gender)

    if(!genderObj) return '-'

    return genderObj.name
  }

  getHasDeficiency(hasDeficiency) {
    const defObj = Object.values(YesNo).find(hd => hd.id == hasDeficiency)

    if(!defObj) return '-'

    return defObj.name
  }
}
