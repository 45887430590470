export default class WaitingListWeb {
  constructor({
    name,
    gender,
    rg,
    cpf,
    birthDate,
    schoolTerm,
    hasDeficiency,
    deficiencyTypeId,
    registrationDate,
    observations,
    cidCode,
    address,
    contacts,
    localTrainingModalities,
  } = {}) {
    this.name = name
    this.birthDate = birthDate
    this.district = address.district
    this.gender = gender
    this.observation = observations
    this.hasDeficiency = hasDeficiency ? 1 : 0
    this.schoolTerm = schoolTerm
    this.rg = rg
    this.cpf = cpf
    this.deficiencyTypeId = deficiencyTypeId
    this.registrationDate = registrationDate
    this.cidCode = cidCode
    this.contacts = contacts
    this.localTrainingModalities = localTrainingModalities
  }
}
