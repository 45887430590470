export default class WaitingListWebCriteria {
  constructor({
    localTrainingId,
    modalityId,
    schoolTerm,
    name,
    birthYear,
    deficiencyTypeId,
    gender,
    presetClass
  } = {}) {
    this.localTrainingId = localTrainingId
    this.modalityId = modalityId
    this.birthYear = birthYear
    this.schoolTerm = schoolTerm
    this.name = name
    this.deficiencyTypeId = deficiencyTypeId
    this.gender = gender
    this.presetClass = presetClass
  }
}
