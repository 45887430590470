import WaitingListWebRepository from '@/shared/http/repositories/socialProject/waitinglistweb.js'
import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiency-type'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import SchoolTerms from '@/shared/enums/schoolTerms'
import Genders from '@/shared/enums/gender.js'
import WaitingListWebCriteria from '@/shared/models/criteria/waitinglistwebcriteria'
import WaitingListWeb from '@/shared/models/waitinglistweb/waitinglistweb-list.js'
import WaitingListTransfer from '@/shared/models/waitinglistweb/transfer.js'
import { dateFormattedFilter, dateHourFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { phoneFilter } from '@/shared/filters/ToPhoneFormatted'
import Roles from '@/shared/mixins/hasRoles'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination
  },

  mixins: [Roles],

  data: () => ({
    id: null,
    criteria: new WaitingListWebCriteria(),
    schoolTerms: Object.values(SchoolTerms),
    genders: Object.values(Genders),
    modalityLocalTraining: [],
    localTrainings: [],
    modalities: [],
    deficiencyTypes: [],
    waitinglist: [],
    kid: {},
    emptyText: 'Realize uma pesquisa utilizando os campos acima',
    showModalDelete: false,
    showModalTransfer: false,
    fields: [
      {
        key: 'name',
        label: 'CRIANÇA',
        class: 'left-header-border text-center'
      },
      {
        key: 'genderName',
        label: 'GÊNERO',
        class: 'middle-header text-center'
      },
      {
        key: 'hasDeficiencyName',
        label: 'TEM DEFICIÊNCIA?',
        class: 'middle-header text-center'
      },
      {
        key: 'registrationDate',
        label: 'DATA DO CADASTRO',
        class: 'middle-header text-center',
        formatter: dateHourFormattedFilter
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'contacts',
        label: 'RESPONSÁVEL',
        class: 'middle-header text-center',
        formatter: 'getContactPhones'
      },
      {
        key: 'schoolTermName',
        label: 'PERÍODO ESCOLAR',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Lista de espera web',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAllDeficiencyTypes()
    this.loadModalityLocalTraining()
  },

  methods: {
    getAllDeficiencyTypes () {
      loading.push()
      DeficiencyTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.deficiencyTypes = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de deficiência', 'ERRO')
        })
    },

    loadModalityLocalTraining () {
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSelectLocalTraining () {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.localTrainings.filter(s => s.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.criteria.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality () {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.criteria.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.localTrainings = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.localTrainings.filter(l => l.id === local.id).length === 0) {
          this.localTrainings.push(local)
        }
      }
    },

    getAll() {
      loading.push()
      WaitingListWebRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.waitinglist = res.data.data.map(w => {
            return new WaitingListWeb(w)
          })

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar lista de espera', 'ERRO')
        })
    },

    onDelete() {
      loading.push()
      WaitingListWebRepository.Delete(this.id)
        .then(res => {
          if(!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDelete = false
          toast.success('Registro inativado com sucesso!', 'EXCLUSÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao inativar registro da lista de espera web', 'ERRO')
        })
    },

    selectRow(id) {
      this.id = null
      this.showModalDelete = true
      this.id = id
    },

    selectTransferRow(id) {
      this.id = null
      this.showModalTransfer = true
      this.id = id
    },

    async getById(id) {
      loading.push()
      await WaitingListWebRepository.GetById(id)
        .then(res => {
          if(!IsSuccessCode(res)) return Promise.reject()

          this.kid = new WaitingListTransfer(res.data.data)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar os dados da criança', 'ERRO')
        })
    },

    async sendToWaitinglist() {
      await this.getById(this.id)
      loading.pop()
      WaitingListWebRepository.Transfer(this.kid)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          this.onDelete()
          this.getAll()
          this.showModalTransfer = false
          toast.success('Criança cadastrada na lista de espera com sucesso', 'SUCESSO')
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao cadastrar criança na lista de espera', 'ERRO')
        })
    },

    getContactPhones (contacts) {
      if (!contacts) return

      const contact = contacts[0]

      let phones = phoneFilter(contact.phone1)

      if(contact.phone2)
        phones += ` / ${phoneFilter(contact.phone2)}`

      if(contact.phone3)
        phones += ` / ${phoneFilter(contact.phone3)}`

      return `${contact.name} ${phones}`
    }
  }
}
